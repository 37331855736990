// src/components/EditMovementModal.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from '../axios';

Modal.setAppElement('#root'); // Necessario per accessibilità

const EditMovementModal = ({ isOpen, onRequestClose, account, movement, onEditSuccess }) => {
    const [form, setForm] = useState({
        scadenza: '',
        descrizione: '',
        cliefor: '',
        tipo: '',
        movimento: '',
        importo: 0,
        aorb: '',
        pagato: false,
    });

    useEffect(() => {
        if (movement) {
            setForm({
                scadenza: movement.scadenza ? new Date(movement.scadenza).toISOString().split('T')[0] : '',
                descrizione: movement.descrizione,
                cliefor: movement.cliefor,
                tipo: movement.tipo,
                movimento: movement.movimento,
                importo: movement.importo,
                aorb: movement.aorb,
                pagato: movement.pagato,
            });
        }
    }, [movement]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    const handleTipoChange = (e) => {
        const { value } = e.target;
        setForm({
            ...form,
            tipo: value,
            cliefor: '',
        });
    };

    const handleMovimentoChange = (value) => {
        setForm({
            ...form,
            movimento: form.movimento === value ? '' : value,
        });
    };

    const handleAorbChange = (value) => {
        setForm({
            ...form,
            aorb: form.aorb === value ? '' : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!form.aorb) {
            alert('Devi selezionare A o B');
            return;
        }
        if (!form.movimento) {
            alert('Devi selezionare Spesa Fissa o Spesa Variabile');
            return;
        }

        const movementData = {
            ...form,
            conto: account,
        };

        try {
            await axios.patch(`/movements/${movement._id}`, movementData);
            alert('Movement updated successfully!');
            onEditSuccess(); // Notifica il successo della modifica
            onRequestClose(); // Chiude il modale dopo l'invio
        } catch (error) {
            console.error('Error updating movement:', error);
        }
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="modal" overlayClassName="overlay">
            <h1 className="text-2xl font-bold mb-4">Modifica movimento</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block mb-2">Scadenza</label>
                    <input type="date" name="scadenza" value={form.scadenza} onChange={handleChange} className="border p-2 w-full" required />
                </div>
                <div>
                    <label className="block mb-2">Descrizione</label>
                    <input type="text" name="descrizione" value={form.descrizione} onChange={handleChange} className="border p-2 w-full" required />
                </div>
                <div>
                    <label className="block mb-2">Tipo</label>
                    <select name="tipo" value={form.tipo} onChange={handleTipoChange} className="border p-2 w-full" required>
                        <option value="">Seleziona Tipo</option>
                        <option value="entrata">Entrata</option>
                        <option value="uscita">Uscita</option>
                    </select>
                </div>
                <div>
                    <label className="block mb-2">{form.tipo === 'entrata' ? 'Cliente' : 'Fornitore'}</label>
                    <input type="text" name="cliefor" value={form.cliefor} onChange={handleChange} className="border p-2 w-full" required />
                </div>
                <div>
                    <label className="block mb-2">Movimento</label>
                    <div className="flex space-x-4">
                        <label>
                            <input
                                type="checkbox"
                                name="movimento"
                                checked={form.movimento === 'fissa'}
                                onChange={() => handleMovimentoChange('fissa')}
                                className="mr-2"
                            />
                            Spesa Fissa
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="movimento"
                                checked={form.movimento === 'variabile'}
                                onChange={() => handleMovimentoChange('variabile')}
                                className="mr-2"
                            />
                            Spesa Variabile
                        </label>
                    </div>
                </div>
                <div>
                    <label className="block mb-2">Importo</label>
                    <input type="number" name="importo" value={form.importo} onChange={handleChange} className="border p-2 w-full" required />
                </div>
                <div>
                    <label className="block mb-2">A o B</label>
                    <div className="flex space-x-4">
                        <label>
                            <input
                                type="checkbox"
                                name="aorb"
                                checked={form.aorb === 'A'}
                                onChange={() => handleAorbChange('A')}
                                className="mr-2"
                            />
                            A
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="aorb"
                                checked={form.aorb === 'B'}
                                onChange={() => handleAorbChange('B')}
                                className="mr-2"
                            />
                            B
                        </label>
                    </div>
                </div>
                <div>
                    <label className="block mb-2">Pagato</label>
                    <input type="checkbox" name="pagato" checked={form.pagato} onChange={() => setForm({ ...form, pagato: !form.pagato })} className="mr-2" />
                    <span>{form.pagato ? 'Sì' : 'No'}</span>
                </div>
                <button type="submit" className="bg-blue-500 text-white p-2 rounded">Salva</button>
            </form>
        </Modal>
    );
};

export default EditMovementModal;
