// src/components/AddMovementModal.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from '../axios';

Modal.setAppElement('#root'); // Necessario per accessibilità

const AddMovementModal = ({ isOpen, onRequestClose, account, onAddSuccess }) => {
    const [form, setForm] = useState({
        scadenza: '',
        descrizione: '',
        cliefor: '',
        tipo: '',
        movimento: '',
        importo: 0,
        aorb: '',
        pagato: false,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    const handleTipoChange = (e) => {
        const { value } = e.target;
        setForm({
            ...form,
            tipo: value,
            cliefor: '',
        });
    };

    const handleMovimentoChange = (value) => {
        setForm({
            ...form,
            movimento: form.movimento === value ? '' : value,
        });
    };

    const handleAorbChange = (value) => {
        setForm({
            ...form,
            aorb: form.aorb === value ? '' : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!form.aorb) {
            alert('Devi selezionare A o B');
            return;
        }
        if (form.tipo === 'uscita' && !form.movimento) {
            alert('Devi selezionare Spesa Fissa o Spesa Variabile');
            return;
        }

        const movementData = {
            ...form,
            conto: account,
        };

        try {
            await axios.post('/movements', movementData);
            alert('Movement added successfully!');
            onAddSuccess(); // Notifica il successo dell'aggiunta
            onRequestClose(); // Chiude il modale dopo l'invio
        } catch (error) {
            console.error('Error adding movement:', error);
        }
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="modal" overlayClassName="overlay">
            <h1 className="text-2xl font-bold mb-4">Aggiungi Movimento</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block mb-2">Scadenza</label>
                    <input type="date" name="scadenza" onChange={handleChange} className="border p-2 w-full rounded" required />
                </div>
                <div>
                    <label className="block mb-2">Descrizione</label>
                    <input type="text" name="descrizione" onChange={handleChange} className="border p-2 w-full rounded" required />
                </div>
                <div>
                    <label className="block mb-2">Tipo</label>
                    <select name="tipo" onChange={handleTipoChange} className="border p-2 w-full rounded" required>
                        <option value="">Seleziona Tipo</option>
                        <option value="entrata">Entrata</option>
                        <option value="uscita">Uscita</option>
                    </select>
                </div>
                <div>
                    <label className="block mb-2">{form.tipo === 'entrata' ? 'Cliente' : 'Fornitore'}</label>
                    <input type="text" name="cliefor" value={form.cliefor} onChange={handleChange} className="border p-2 w-full rounded" required />
                </div>
                {form.tipo === 'uscita' && (
                    <>
                        <div>
                            <label className="block mb-2">Movimento</label>
                            <div className="flex space-x-4">
                                <button
                                    type="button"
                                    className={`p-2 rounded ${form.movimento === 'fissa' ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
                                    onClick={() => handleMovimentoChange('fissa')}
                                >
                                    Spesa Fissa
                                </button>
                                <button
                                    type="button"
                                    className={`p-2 rounded ${form.movimento === 'variabile' ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
                                    onClick={() => handleMovimentoChange('variabile')}
                                >
                                    Spesa Variabile
                                </button>
                            </div>
                        </div>
                        <div>
                            <label className="block mb-2">Pagato</label>
                            <div className="flex items-center">
                                <button
                                    type="button"
                                    className={`p-2 rounded ${form.pagato ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
                                    onClick={() => setForm({ ...form, pagato: !form.pagato })}
                                >
                                    {form.pagato ? 'Sì' : 'No'}
                                </button>
                            </div>
                        </div>
                    </>
                )}
                <div>
                    <label className="block mb-2">Importo</label>
                    <input type="number" name="importo" onChange={handleChange} className="border p-2 w-full rounded" required />
                </div>
                <div>
                    <label className="block mb-2">A o B</label>
                    <div className="flex space-x-4">
                        <button
                            type="button"
                            className={`p-2 rounded ${form.aorb === 'A' ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
                            onClick={() => handleAorbChange('A')}
                        >
                            A
                        </button>
                        <button
                            type="button"
                            className={`p-2 rounded ${form.aorb === 'B' ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
                            onClick={() => handleAorbChange('B')}
                        >
                            B
                        </button>
                    </div>
                </div>
                <button type="submit" className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200">Salva</button>
            </form>
        </Modal>
    );
};

export default AddMovementModal;
